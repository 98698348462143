import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import '../css/Carousel.css'; // Importa tu archivo de estilos CSS personalizado

function Slide() {
    const images = [
        {
            original: require('../assets/comprobante.png'),
        },
        {
            original: require('../assets/empresas.png'),
        },
        {
            original: require('../assets/logoneurosoft.png'),
        },
    ];
    
    return (
        <div className="gallery-container">
            <ImageGallery items={images}
                showPlayButton={false}
                showFullscreenButton={false}
                showThumbnails={false}
                showNav={false}
                showBullets={true}
                autoPlay={true}
                slideInterval={5000}
                slideDuration={1000}
                thumbnailPosition="left"
            />
        </div>
    );
}

export default Slide;