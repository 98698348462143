import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Link as MuiLink,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
} from '@mui/material';
import Slide from '../components/CarouselEmpresas';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LoadingButton from '@mui/lab/LoadingButton';
import { FC, useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '../components/FormInput';
//import { ReactComponent as NeuroLogo } from '../assets/logoneurosoft.svg';
import styled from '@emotion/styled';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {Buffer} from 'buffer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// 👇 Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
  text-decoration: none;
  color: #3683dc;
  &:hover {
    text-decoration: underline;
    color: #5ea1b6;
  }
`;

// 👇 Styled Material UI Link Component
export const OauthMuiLink = styled(MuiLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f6f7;
  border-radius: 1;
  padding: 0.6rem 0;
  column-gap: 1rem;
  text-decoration: none;
  color: #393e45;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
  }
`;


// 👇 Search Schema with Zod
const searchSchema = object({
  serie: string()
    .min(4, 'La serie debe tener 4 caracteres')
    .max(4, 'La serie debe tener 4 caracteres'),
  ruc: string()
    .min(11, 'El RUC debe tener 11 digitos')
    .max(11, 'El RUC debe tener 11 digitos'),
  numero: string()
    .min(1, 'El número es requerido'),
  fecha: string()
    .min(1, 'La fecha es requerida'),
});

const MySwal = withReactContent(Swal)


// 👇 Infer the Schema to get the TS Type
type ISearch = TypeOf<typeof searchSchema>;

const SearchPage: FC = () => {

  const [tipo, setTipo] = useState('01');
  const [success, setSuccess] = useState(false);
  const [zip, setZip] = useState(false)
  const [result, setResult] = useState({cdr:'',pdf:'',xml:''});
  const [file, setFile] = useState('');
  const [open, setOpen] = useState(false);

  const handleChangeTipoDoc = (event: SelectChangeEvent) => {
    setTipo(event.target.value as string);
    setSuccess(false)
  };

  // 👇 Default Values
  const defaultValues: ISearch = {
    ruc: '',
    serie: '',
    numero: '',
    fecha: ''
  };

  const year = new Date().getFullYear().toString()
  // 👇 The object returned from useForm Hook
  const methods = useForm<ISearch>({
    resolver: zodResolver(searchSchema),
    defaultValues,
  });

  const searchCpe = async (ruc:string, serie:string, numero:number, fecha:string) => {
    await fetch('https://admin.factura.vip:5000/api/consulta/store', {
       method: 'POST',
       body: JSON.stringify({
          Ruc: ruc,
          Serie: serie,
          Numero: numero,
          Fecha: fecha,
          Tipo: tipo
       }),
       headers: {
          'Content-type': 'application/json; charset=UTF-8',
       },
    })
       .then((response) => response.json())
       .then((data) => {
        MySwal.close()
        if(data.success){
          setSuccess(true)
          setZip(data.zip)
          setResult({
            cdr:data.data.cdr,
            pdf:data.data.pdf,
            xml:data.data.xml,
          });
          setFile(ruc+"-"+tipo+"-"+serie+"-"+numero);
          MySwal.fire({
            title: data.msg,
            text:(data.zip?"El archivo XML fue comprimido para garantizar su autendicidad, extraerlo para validar desde el portal SUNAT.":""),
            allowEscapeKey:false,
            allowOutsideClick: false,
            showConfirmButton:true,
            confirmButtonText: 'OK',
            confirmButtonColor: '#2e7d32',
            icon:'success',
          })
        }else{
          setSuccess(false)
          MySwal.fire({
            title: data.msg,
            allowEscapeKey:false,
            allowOutsideClick: false,
            showConfirmButton:true,
            confirmButtonText: 'Entiendo',
            confirmButtonColor: '#1976d2',
            icon:'info'
          })
        }
       })
       .catch((err) => {
          MySwal.fire({
            title: 'Ha ocurrido un error en la consulta',
            allowEscapeKey:false,
            allowOutsideClick: false,
            showConfirmButton:true,
            confirmButtonText: 'Entiendo',
            confirmButtonColor: '#1976d2',
            icon:'error'
          })
          console.log(err.message);
       });
  };

  
  // 👇 Submit Handler
  const onSubmitHandler: SubmitHandler<ISearch> = async (values: ISearch) => {

    if((''+values.ruc).trim().length!==11 || (''+values.serie).trim().length!==4 || !(Number(values.numero)>0) ){
      MySwal.fire({
        title: 'Los datos ingresados no son válidos',
        allowEscapeKey:false,
        allowOutsideClick: false,
        showConfirmButton:true,
        confirmButtonText: 'Entiendo',
        confirmButtonColor: '#1976d2',
        icon:'error'
      })
      return;
    }
    MySwal.fire({
      title: <p>Buscando comprobante ...</p>,
      allowEscapeKey:false,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading()
      },
    })
    await searchCpe(values.ruc, (''+values.serie).toUpperCase(), Number(values.numero), values.fecha)
  };

  const handleClickXml = () => {
   const buffer = Buffer.from(result.xml);
   const blob = new Blob([buffer]);                      
   const url = window.URL.createObjectURL(blob);
   const a = document.createElement("a");
   document.body.appendChild(a);
   a.href = url;
   a.target = '_self'
   a.download = file+(zip?'.ZIP':'.XML');
   a.click();
   window.URL.revokeObjectURL(url);
  };

  const handleClickCdr = () => {
    const buffer = Buffer.from(result.cdr);
    const blob = new Blob([buffer]);                      
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.target = '_self'
    a.download = "R-"+file+".XML";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleClickPdf = () => {
    const buffer = Buffer.from(result.pdf);
    const blob = new Blob([buffer]);                      
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.target = '_self'
    a.download = file+".pdf";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 👇 JSX to be rendered
  return (
    <Container
      maxWidth={false}
      sx={{ height: '100vh', backgroundColor: { xs: '#fff', md: '#f4f4f4' } }}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{ width: '100%', height: '100%' }}
      >
        <Grid
          item
          sx={{ maxWidth: '80rem', width: '100%', backgroundColor: '#fff' }}
        >
          <FormProvider {...methods}>
            <Grid
              container
              sx={{
                boxShadow: { sm: '0 0 5px #ddd' },
                py: '2.5rem',
                px: '1rem',
              }}
            >
              <Grid
                item
                container
                justifyContent='space-between'
                rowSpacing={5}
                sx={{
                  maxWidth: { sm: '100rem' },
                  marginInline: 'auto',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  sx={{ borderRight: { sm: '1px solid #ddd' } }}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    component='form'
                    noValidate
                    autoComplete='off'
                    sx={{ maxWidth: { sm: '25rem' }, }}
                    onSubmit={methods.handleSubmit(onSubmitHandler)}
                  >
                    <Typography
                      variant='h5'
                      component='h1'
                      sx={{ textAlign: 'center', mb: '1.5rem' }}
                    >
                      Consulte su comprobante electrónico
                    </Typography>

                    <FormControl fullWidth sx={{ mb: '1.5rem' }}>
                      <InputLabel id="tipoDoc">Tipo Documento</InputLabel>
                      <Select
                        labelId="tipoDoc"
                        id="tipo"
                        name="tipo"
                        value={tipo}
                        label="Tipo Documento"
                        required
                        onChange={handleChangeTipoDoc}
                      >
                        <MenuItem value={'01'}>FACTURA ELECTRONICA</MenuItem>
                        <MenuItem value={'03'}>BOLETA ELECTRONICA</MenuItem>
                        <MenuItem value={'07'}>NOTA DE CREDITO ELECTRONICA</MenuItem>
                        <MenuItem value={'08'}>NOTA DE DEBITO ELECTRONICA</MenuItem>
                      </Select>
                    </FormControl>

                    <FormInput
                      label='RUC del emisor'
                      type='text'
                      name='ruc'
                      required
                    />
                    <FormInput
                      label='Serie'
                      type='text'
                      name='serie'
                      required
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                    <FormInput
                      label='Número'
                      type='text'
                      name='numero'
                      required
                    />

                    <FormInput
                      label='Fecha de Emisión'
                      type='date'
                      name='fecha'
                      required
                      focused
                    />
                    {success?
                    <Stack display='flex'
                    flexDirection='row'
                    alignContent='center'
                    justifyContent= 'center' sx={{ mt:'-1rem' }}>
                      {result.xml?<Button 
                        variant="outlined"
                        color='primary'
                        sx={{margin:'1rem'}}
                        onClick={() => { handleClickXml() }}
                        >
                        <AssignmentIcon/>XML
                        </Button>:''}
                      {result.cdr?<Button
                        variant="outlined"
                        color='success'
                        sx={{margin:'1rem'}}
                        onClick={() => { handleClickCdr() }}
                        ><AssignmentTurnedInIcon/>CDR
                        </Button>:''}
                      {result.pdf?<Button 
                        variant="outlined"
                        color='error'
                        sx={{margin:'1em'}}
                        onClick={() => { handleClickPdf() }}
                        ><PictureAsPdfIcon/>PDF
                        </Button>:''}
                    </Stack>
                    :''}
                    { result.xml || result.cdr || result.pdf?<Stack display='flex'
                    flexDirection='row'
                    alignContent='center'
                    justifyContent= 'center' sx={{ mt:'-0.3rem' }}><a href="/#" rel="noopener noreferrer" onClick={handleClickOpen}>¿Qué representan estos archivos?</a></Stack>:''}
                    <LoadingButton
                      loading={false}
                      type='submit'
                      variant='contained'
                      sx={{
                        py: '0.8rem',
                        mt: 2,
                        width: '80%',
                        marginInline: 'auto',
                      }}
                    >
                      
                      <SearchIcon/>
                      Buscar
                    </LoadingButton>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} 
                    display='flex'
                    flexDirection='column'
                    alignContent='center'
                    justifyContent= 'center'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignContent='center'
                    justifyContent= 'center'
                    alignItems='center'
                  >
                    <Slide/>
                  </Box>
                </Grid>
              </Grid>
              <Grid container justifyContent='center'>
                <Stack sx={{ mt: '1.5rem', textAlign: 'center' }}>
                  <Typography sx={{ fontSize: '0.9rem', mb: '0.5rem', fontWeight: 'bold' }}>
                    Telf: (062) 511550 - Cel: 954370221 {' '} <br />
                    Cel. Soporte: 997578199 {' '}
                  </Typography>
                  <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', fontStyle: 'italic' }}>
                    © {year}. Todos los derechos reservados - CONSULTORA CONSTRUCTORA NEUROSYSTEM PERU S.A.C.{' '} <br />
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Qué representan estos archivos?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box display='flex'
                    flexDirection='column'
                    alignContent='start'
                    justifyContent= 'start'
                    alignItems='start'>
              <Box display='flex'
                      flexDirection='row'
                      alignContent='center'
                      justifyContent= 'start'
                      alignItems='center'  sx={{ mb: '10px' }}>
                <AssignmentIcon color='primary' fontSize="medium"/><Typography sx={{ color: "#1976d2" }}> XML</Typography>
                <Typography sx={{ color: "#5A5A5A", marginLeft: '10px' }}>Formato generado por el sistema de facturación, este contiene datos del comprobante que será informado a SUNAT.</Typography>
              </Box>
              <Box display='flex'
                      flexDirection='row'
                      alignContent='center'
                      justifyContent= 'start'
                      alignItems='center'  sx={{ mb: '10px' }}>
                <AssignmentTurnedInIcon color='success' fontSize="medium"/><Typography sx={{ color: "#2e7d32" }}> CDR</Typography>
                <Typography sx={{ color: "#5A5A5A", marginLeft: '10px' }}>Formato generado por SUNAT donde nos informa el estado del comprobante despues enviar el XML a SUNAT.</Typography>
              </Box>
              <Box display='flex'
                      flexDirection='row'
                      alignContent='center'
                      justifyContent= 'start'
                      alignItems='center'  sx={{ mb: '10px' }}>
                <PictureAsPdfIcon color='error' fontSize="medium"/><Typography sx={{ color: "#d32f2f" }}> PDF </Typography>
                <Typography sx={{ color: "#5A5A5A", marginLeft: '10px' }}>Representación visual del comprobante electrónico.</Typography>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SearchPage;
